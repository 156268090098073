.homeDiv {
    display: flex;
    flex-direction: column;
  }
  .HomeSection1 {
    padding-top: 7em;
    position: relative;
    overflow: hidden;
    padding-bottom: 4em;
    background: #07775d;
  }
  .HomeSection1b {
    padding-top: 7em;
    position: relative;
    overflow: hidden;
    padding-bottom: 4em;
    background: #07775d;
  }
  .HomeSection1_trade_img {
    position: absolute;
    top: 0;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .unlock_potential_area {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .unlock_potential_area1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 5em;
  }
  .unlock_potential_area_txt {
    width: max-content;
    background: #ffcf88;
    padding: 5px 25px;
    font-size: 14px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
    color: #66491f;
  }
  .unlock_potential_area_title {
    font-size: 48px;
    line-height: 1.2;
    font-weight: 600;
    color: #fff;
  }
  .unlock_potential_area_para {
    color: #b7f0e3c4;
    margin-top: 1em;
    font-size: 18px;
  }
  .unlock_potential_area_2 {
    width: 70%;
  }
  .HomeSection1::before {
    content: "";
    opacity: 0.3;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgb(7 120 94) 0%,
      #000000 0%,
      rgb(7 120 94) 88%
    );
    position: absolute;
    top: 0%;
    left: 0px;
    right: 0px;
    height: 424px;
    width: 100%;
  }
  .HomeSection1::after {
    content: "";
    background: rgb(85, 85, 85);
    background: linear-gradient(
      180deg,
      rgba(85, 85, 85, 0) 0%,
      rgb(11 96 76) 86%
    );
    position: absolute;
    bottom: -2px;
    left: 0px;
    right: 0px;
    height: 10%;
    width: 100%;
  }
  .HomeSection1_area {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .HomeSection1_area_1 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .HomeSection1_area_2 {
    width: 50%;
    margin-left: 3em;
  }
  .HomeSection1_area_1_cont1_txt {
    display: flex;
    flex-direction: column;
  }
  .HomeSection1_area_1_cont1_txt1 {
    color: #ffcf88;
    font-size: 28px;
    font-weight: 700;
  }
  .HomeSection1_area_1_cont1_txt2 {
    color: #ffffff;
    font-size: 78px;
    font-weight: 800;
    margin-top: 30px;
    margin-bottom: 40px;
    line-height: 1.1;
    letter-spacing: 3px;
  }
  .HomeSection1_area_1_cont1_txt3 {
    color: #cbe5df;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 60px;
  }
  .HomeSection1_area_1_cont1_cards {
    display: flex;
    /* align-items: center; */
    width: 100%;
  }
  .HomeSection1_area_1_cont1_cards_card1 {
    display: flex;
    align-items: center;
    padding: 1em;
    border: solid 1px #dedede;
    border-radius: 20px;
    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 16%) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    margin-right: 25px;
    max-width: 250px;
    color: #000;
  }
  .HomeSection1_area_1_cont1_cards_card1::after {
    content: "";
    /* background: rgb(85, 85, 85); */
    background: linear-gradient(180deg, rgb(7 88 76 / 33%) 0%, rgb(5 101 87) 86%);
    position: absolute;
    bottom: -2px;
    left: -5px;
    right: 0px;
    height: 71%;
    width: 104%;
  }
  .HomeSection1_area_1_cont1_cards_card1_cont1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
  }
  .HomeSection1_area_1_cont1_cards_card1_cont1_title {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
  }
  
  .HomeSection1_area_1_cont1_cards_card1_cont1_para {
    color: #ffcf88;
  }
  .HomeSection1_area_1_cont1_cards_card1_cont2 {
    position: relative;
    z-index: 1;
    width: 100px;
  }
  .HomeSection1_area_1_cont1_cards_card1_cont2_img {
    width: 100%;
  }
  .HomeSection1_area_1_cont1_button_div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2em;
  }
  .HomeSection1_area_1_cont1_button {
    padding: 15px 10px;
    background: #ffffff;
    width: 200px;
    border-radius: 50px;
    color: #07785e;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .HomeSection1_area_2_form_divb {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 20px;
    border: 1px solid #37948a;
    background: #0000003b;
    padding: 35px 25px;
    color: #fff;
  }
  .HomeSection1_area_2_form_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 20px;
    border: 1px solid #d4d4d4;
    background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
    padding: 35px 25px;
    color: #000;
  }
  .HomeSection1_area_2_form_div_title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 28px;
    font-weight: 700;
  }
  .HomeSection1_area_2_form_div_title_para {
    color: #07775d;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    display: block;
    margin-bottom: 30px;
    width: 100%;
    margin-top: 10px;
  }
  .HomeSection1_area_2_form_div_body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .HomeSection1_area_2_form_div_body_input {
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: solid 1px #dadada;
    background: #fff;
    width: 100%;
  }
  .HomeSection1_area_2_form_div_button_div {
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .HomeSection1_area_2_form_div_body_input_txt_area {
    background: #000;
    border: solid 1px #282727;
    padding: 15px;
    border-radius: 5px;
  }
  .HomeSection1_area_2_form_div_button {
    font-size: 16px;
    background: #07775d !important;
    border-radius: 50px;
    padding: 14px 25px;
    color: #fff !important;
    font-weight: 600;
    text-align: center;
    width: 200px;
  }
  .HomeSection1_area_2_form_div_buttonb {
    font-size: 16px;
    background: #ffcf88 !important;
    border-radius: 50px;
    padding: 12px 25px;
    color: #67491f !important;
    font-weight: 500;
    text-align: center;
    width: 200px;
  }
  .HomeSection2 {
    background: #fffbf5;
    position: relative;
  }
  .HomeSection2_area_img {
    width: 70% !important;
    margin-bottom: 5em !important;
  }
  .HomeSection2::after {
    content: "";
    /* background: rgb(85, 85, 85); */
    background: linear-gradient(
      180deg,
      rgba(85, 85, 85, 0) 0%,
      rgb(255 251 246) 86%
    );
    position: absolute;
    bottom: 63px;
    left: 0px;
    right: 0px;
    height: 20%;
    width: 100%;
  }
  .HomeSection2_area {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .HomeSection3 {
    /* padding: 8em 0em; */
    padding-top: 150px;
    padding-bottom: 20px;
    position: relative;
    background: #000;
  }
  
  .HomeSection3_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .HomeSection3_area_title {
    font-size: 52px;
    font-weight: 700;
    margin-bottom: -4em;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
    color: #fffbf5;
  }
  .HomeSection3_area_title_para {
    font-size: 18px;
    font-weight: 400;
    color: #909090;
    margin-top: 0em;
  }
  .HomeSection3_area_video_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .HomeSection3_area_video {
    width: 100%;
  }
  
  .HomeSection4 {
    background: #fffbf5;
    padding: 6em 0em;
  }
  .HomeSection4_area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .HomeSection4_area_title_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 5em;
  }
  .HomeSection4_area_title {
    font-size: 52px;
    font-weight: 700;
    position: relative;
    color: #000;
  }
  .HomeSection4_area_para {
    font-size: 18px;
    font-weight: 400;
    color: #909090;
    margin-top: 1em;
    width: 75%;
  }
  
  .HomeSection4_area_body {
    display: flex;
    align-items: flex-start;
    position: relative;
  }
  .HomeSection4_area_body_cont1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
  }
  .HomeSection4_area_body_cont2 {
    width: 100%;
    margin-left: 2em;
    position: sticky;
    left: 0;
    top: 0;
  }
  .HomeSection4_area_body_cont1_div1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 150px;
  }
  .HomeSection4_area_body_cont1_div1_title {
    margin-bottom: 30px;
    font-size: 32px;
    font-weight: 600;
    color: #07775d;
  }
  .HomeSection4_area_body_cont1_div1_body_cont {
    margin-bottom: 10px;
    color: #000;
  }
  
  .HomeSection5 {
    padding-top: 120px;
    padding-bottom: 150px;
    position: relative;
    background: #07785e;
  }
  
  .HomeSection5_area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .HomeSection5_area_title_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 8em;
  }
  .HomeSection5_area_title {
    font-size: 52px;
    font-weight: 700;
    position: relative;
    color: #fff;
  }
  .HomeSection5_area_para {
    font-size: 18px;
    font-weight: 400;
    color: #bfe4dc;
    margin-top: 1em;
    width: 75%;
  }
  
  .HomeSection5_area_body {
    display: flex;
    flex-direction: column;
    max-width: 1140px;
    padding: 0em 2em;
  }
  .HomeSection5_area_body_cont1 {
    display: flex;
  }
  .HomeSection5_area_body_cont1_div1 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .HomeSection5_area_body_cont1_div1_week {
    color: #fff;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
  }
  .HomeSection5_area_body_cont1_div1_title {
    display: block;
    font-size: 1.3em;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: right;
    color: #bfe0d8;
  }
  .HomeSection5_area_body_cont1_div1_para {
    color: #ffcf88;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 60px;
  }
  .HomeSection5_area_body_cont1_div_icon_div {
    position: relative;
    width: 40%;
    padding: 0em 1em;
  }
  
  .HomeSection5_area_body_cont1_div_icon_div::before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ffcf88;
    background: #06795f;
    left: 0px;
    right: 0px;
    position: absolute;
    margin: auto;
    filter: drop-shadow(0px 5px 20px rgba(255, 255, 255, 0.25));
    -webkit-filter: drop-shadow(0px 5px 20px rgba(255, 255, 255, 0.25));
    z-index: 9;
  }
  
  .HomeSection5_area_body_cont1_div_icon_div::after {
    content: "";
    /* background: rgb(255 206 0 / 26%); */
    background: linear-gradient(
      180deg,
      rgb(174 147 34) 51%,
      rgba(0, 0, 0, 0) 86%
    );
    padding-right: 1px;
    width: 1px;
    align-self: center;
    height: 100%;
    display: inline-block;
    filter: drop-shadow(0px 5px 20px rgba(255, 255, 255, 0.25));
    -webkit-filter: drop-shadow(0px 5px 20px rgb(255 206 0 / 26%));
    left: 0px;
    right: 0px;
    margin: auto;
    position: absolute;
  }
  .HomeSection5_area_body_cont1_div3 {
    width: 100%;
    color: #bfe0d8;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 60px;
    margin-top: 5px;
  }
  .HomeSection5_area_body_btn_div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
  }
  .HomeSection5_area_body_btn {
    font-size: 16px;
    background: #ffffff;
    border-radius: 50px;
    padding: 14px 25px;
    color: #06795f !important;
    font-weight: 500;
    text-align: center;
  }
  
  .HomeSection6 {
    position: relative;
    padding: 7em 0em;
    padding-bottom: 2em;
    background: #fffbf5;
  }
  .HomeSection7 {
    position: relative;
    padding: 7em 0em;
    padding-bottom: 2em;
    background: #06795f;
    color: #fff;
    overflow: hidden;
  }
  .HomeSection7_area {
    max-width: 1140px;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .HomeSection7_area_body {
    display: flex;
    flex-direction: column;
  }
  .HomeSection7_area_title {
    font-size: 52px;
    font-weight: 700;
    position: relative;
    color: #ffffff;
  }
  .HomeSection7_area_body_details {
    margin-bottom: 0px;
    position: relative;
    display: flex;
  }
  .HomeSection7_area_body_details_div1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 3em;
  }
  .HomeSection7_area_body_details_div1_title_div {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
  }
  .HomeSection7_area_body_details_div1_title_div1 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .HomeSection7_area_body_details_div1_title_div2 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .HomeSection7_area_body_details_div1_title_div3 {
    color: #ffcf88;
    font-size: 16px;
    font-weight: 600;
    /* margin-bottom: 20px; */
  }
  .HomeSection7_area_body_details_div1_content_txt {
    /* margin-bottom: 2em; */
    color: #b7d1cb;
    font-size: 18px;
    font-weight: 400;
  }
  .HomeSection7_area_body_details_div1_unique_txt {
    border-radius: 15px;
    background: #135949;
    padding: 24px;
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
  }
  .HomeSection7_area_body_details_div1_unique_txt_title {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  
  .HomeSection7_area_body_details_div1_unique_txt_para {
    color: #d1c8c8;
    font-size: 18px;
    font-weight: 400;
  }
  .HomeSection7_area_body_details_div_socials {
    display: flex;
    align-items: center;
  }
  .HomeSection7_area_body_details_div_socials1 {
    display: flex;
    align-items: center;
    margin-right: 2em;
  }
  .HomeSection7_area_body_details_div_socials1_cont1 {
    margin-right: 15px;
    background: white;
    border-radius: 50%;
  }
  .HomeSection7_area_body_details_div_socials1_cont2 {
    display: flex;
    flex-direction: column;
  }
  .HomeSection7_area_body_details_div_socials1_cont2_title {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    display: block;
  }
  .HomeSection7_area_body_details_div_socials1_cont2_para {
    color: #d1c8c8;
    font-size: 18px;
    font-weight: 400;
    display: block;
  }
  .HomeSection7_area_body_details::after {
    content: "";
    width: 400px;
    height: 400px;
    border-radius: 50%;
    opacity: 1;
    background: rgb(0, 0, 0);
    background: radial-gradient(
      circle,
      rgb(255 255 255) 0%,
      rgb(255 251 245) 0%,
      rgb(255 251 245) 88%
    );
    filter: blur(90px);
    -webkit-filter: blur(90px);
    position: absolute;
    right: 0px;
    bottom: 20%;
  }
  .HomeSection7_area_body_details_div2_img {
    position: relative;
    z-index: 1;
  }
  .HomeSection7_area_body_details_div2 {
    position: relative;
    width: 60%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 9em;
  }
  .HomeSection7_area_body_details_div2::after {
    content: "";
    /* background: rgb(85, 85, 85); */
    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 0%) 0%,
      #068166 100%,
      #0a765e 100%
    );
    position: absolute;
    bottom: 0;
    /* top: 0; */
    /* margin: auto; */
    right: 0px;
    height: 16%;
    width: 100%;
    z-index: 1;
  }
  /* ========================== */
  /* ========================== */
  /* ========================== */
  /* ========================== */
  /* ========================== */
  
  .HomeSection8 {
    position: relative;
    padding: 7em 0em;
    padding-bottom: 7em;
    background: #fffbf5;
    color: #000;
  }
  
  .tarding_skills_top_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .tarding_skills_top_wrapper .six_month {
    position: sticky;
    left: 0px;
    right: 0px;
    top: 0px;
    text-align: right;
    font-size: 1.4em;
    color: #07775d;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    top: 137px;
    width: 100%;
    display: block;
    margin-bottom: -16px;
  }
  .tarding_skills_wrapper ul {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
  }
  .tarding_skills_wrapper ul li {
    position: sticky;
    top: 150px;
    z-index: 1;
    --index: 1;
    padding-top: calc(var(--index) * 38px);
  }
  .tarding_skills_wrapper ul li .tarding_skills_box {
    box-shadow: 0px -16px 30px rgb(0 0 0 / 39%);
    border-radius: 12px;
    overflow: clip;
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .tarding_skills_wrapper ul li:nth-child(2) {
    --index: 2;
    z-index: 2;
  }
  .tarding_skills_wrapper ul li:nth-child(3) {
    --index: 3;
    z-index: 3;
  }
  .tarding_skills_wrapper ul li:nth-child(4) {
    --index: 4;
    z-index: 4;
  }
  .tarding_skills_wrapper ul li:nth-child(5) {
    --index: 5;
    z-index: 5;
  }
  .HomeSection8_area {
    display: flex;
  }
  .HomeSection8_area_1_cont {
    width: 100%;
    margin-right: 2em;
  }
  .HomeSection8_area_1 {
    position: sticky;
  
    top: 150px;
  
    display: flex;
    flex-direction: column;
  }
  .HomeSection8_area_1_div1 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
  }
  .HomeSection8_area_1_div1_img {
    width: 30px;
    margin-right: 15px;
  }
  .HomeSection8_area_1_div2 {
    font-size: 54px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .HomeSection8_area_1_div3 {
    color: #848484;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 100px;
    width: 80%;
  }
  .HomeSection8_area_1_div4 {
    display: flex;
    align-items: center;
  }
  .HomeSection8_area_1_div4_btn {
    font-size: 16px;
    background: #07775d;
    border-radius: 50px;
    padding: 14px 25px;
    color: #fff !important;
    font-weight: 500;
    text-align: center;
    margin-right: 10px;
  }
  .HomeSection8_area_2 {
    display: flex;
    width: 70%;
  }
  .tarding_skills_top_wrapper .six_month {
    position: sticky;
    left: 0px;
    right: 0px;
    text-align: right;
    font-size: 14px;
    color: #07775d;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    top: 137px;
    width: 100%;
    display: block;
    margin-bottom: -16px;
  }
  
  .HomeSection8_area_1_div1 span {
    color: #07775d;
    font-weight: 700;
  }
  /* ========================== */
  /* ========================== */
  /* ========================== */
  /* ========================== */
  /* ========================== */
  /* ========================== */
  /* ========================== */
  
  .HomeSection7_area_body_navigation {
    align-items: center;
    justify-content: space-between;
    display: flex;
    position: relative;
    margin-top: 120px;
  }
  .HomeSection7_area_body_navigation::after {
    content: "";
    width: 100%;
    height: 5px;
    background: #5dc2ab;
    top: -60px;
    position: absolute;
    border-radius: 50px;
  }
  .HomeSection7_area_body_navigation_cont1 {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0px 5px;
    cursor: pointer;
  }
  .HomeSection7_area_body_navigation_cont1
    .HomeSection7_area_body_navigation_cont1_img_img {
    filter: grayscale(1);
  }
  .HomeSection7_area_body_navigation_cont1_active
    .HomeSection7_area_body_navigation_cont1_img_img {
    filter: none;
  }
  .HomeSection7_area_body_navigation_cont1:hover
    .HomeSection7_area_body_navigation_cont1_txt_title {
    font-weight: 700;
  }
  .HomeSection7_area_body_navigation_cont1_active {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0px 5px;
    cursor: pointer;
  }
  .HomeSection7_area_body_navigation_cont1_active:hover
    .HomeSection7_area_body_navigation_cont1_txt_title {
    font-weight: 700;
  }
  .HomeSection7_area_body_navigation_cont1_active::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 14px solid #ffcf88;
    display: inline-block;
    position: absolute;
    top: -73px;
    left: 50%;
    transform: translateX(-50%);
  }
  .selected {
    content: "";
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 14px solid #ffcf88;
    display: inline-block;
    position: absolute;
    top: -73px;
    left: 50%;
    transform: translateX(-50%);
  }
  .HomeSection7_area_body_navigation_cont1_active::after {
    content: "";
    position: absolute;
    background: #ffcf88;
    width: 100%;
    height: 5px;
    top: -60px;
    left: 0px;
    z-index: 9;
    border-radius: 50px;
    opacity: 1;
  }
  .HomeSection7_area_body_navigation_cont1_img {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    display: block;
    position: relative;
    transform: scale(1);
    transition: 0.6s all;
  }
  .HomeSection7_area_body_navigation_cont1_img::after {
    content: "";
    background: rgb(85, 85, 85);
    background: linear-gradient(180deg, rgba(85, 85, 85, 0) 0%, rgb(0 0 0) 86%);
    position: absolute;
    bottom: -6px;
    left: 0px;
    right: 0px;
    height: 35%;
    width: 100%;
  }
  
  .HomeSection7_area_body_navigation_cont1_txt {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* ========================== */
  /* ========================== */
  /* ========================== */
  /* ========================== */
  /* ========================== */
  /* ========================== */
  /* ========================== */
  .HomeSection9 {
    padding: 6em 0em;
    position: relative;
    /* padding-top: 12em; */
    background: #fffbf6;
  }
  .HomeSection9_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .HomeSection9_area_1 {
    position: relative;
    width: 80%;
    margin-left: 0em;
    height: 600px;
  }
  .HomeSection9_area_1::after {
    content: "";
    width: 400px;
    height: 400px;
    border-radius: 50%;
    opacity: 1;
    background: rgb(0, 0, 0);
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 0%,
      rgb(174 147 35 / 68%) 0%,
      rgba(0, 0, 0, 0.9136029411764706) 88%
    );
    filter: blur(90px);
    -webkit-filter: blur(90px);
    position: absolute;
    right: 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -9;
  }
  
  .HomeSection9_area_1_img {
    max-width: 100%;
    height: auto;
  }
  .HomeSection9_area_1_video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 230px;
    z-index: -1;
  }
  .HomeSection9_area_2 {
    display: flex;
    flex-direction: column;
    margin-left: 4em;
    width: 100%;
  }
  .HomeSection8_area_2_title {
    opacity: 1;
    /* transform: translateY(0); */
    /* transition: all 1s; */
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 25px;
    color: #000;
  }
  .HomeSection9_area_2_para_div {
    font-size: 18px;
    color: #757575;
  }
  .HomeSection9_area_2_para_div span {
    color: #067b61;
    font-weight: 600;
  }
  .HomeSection9_area_2_para_div_list1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
  .HomeSection9_area_2_para_div_list1_icon {
    /* background: #ddb272; */
    margin-right: 10px;
  }
  .HomeSection10 {
    position: relative;
    padding: 6em 0em;
    background: #fffbf6;
  }
  .HomeSection10_area {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .HomeSection10_area_1 {
    color: #000;
    text-align: center;
    font-size: 48px;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: 30px;
    width: 65%;
  }
  .HomeSection10_area_2 {
    display: flex;
    align-items: flex-start;
  }
  .HomeSection10_area_2_cont1 {
    width: 100%;
    margin-right: 3em;
    position: sticky;
    top: 20%;
  }
  .HomeSection10_area_2_cont2 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .HomeSection10_area_2_cont2_div1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 4em;
  }
  .HomeSection10_area_2_cont2_div1_titlediv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    font-weight: 700;
    font-size: 18px;
    color: #057c62;
  }
  .HomeSection10_area_2_cont2_div1_body {
    color: #666666;
  }
  .HomeSection11 {
    position: relative;
    padding: 6em 0em;
    background: #fffbf6;
  }
  .HomeSection11_area {
    display: flex;
    align-items: center;
    padding: 6em 4em;
    background: #067a60;
    background-image: linear-gradient(45deg, #00000063, transparent);
    border-radius: 15px;
    width: 100%;
    max-width: 1024px;
    margin: auto;
  }
  .unlock_potential_area1_icon_img {
    width: 480px;
    margin-top: 0em;
  }
  .HomeSection11_area_1 {
    width: 100%;
    /* margin-right: 3em; */
    display: flex;
  
    flex-direction: column;
    align-items: flex-start;
  }
  .HomeSection11_area_1_title {
    font-size: 45px;
    /* width: 60%; */
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 10px;
    color: #fff;
  }
  .HomeSection11_area_1_para {
    color: #dafff7b8;
    font-size: 24px;
    width: 100%;
    line-height: 1.2;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .HomeSection11_area_1_btn {
    background: #067a60;
    padding: 15px;
    width: 200px;
    border-radius: 50px;
    font-weight: 600;
    color: #fff;
  }
  .HomeSection11_area_2 {
    width: 45%;
  }
  .HomeSection11_area_2_img {
    width: 100%;
  }
  
  /* ====================== */
  
  
  .HomeSection12 {
    position: relative; 
    padding:  6em 0em;
    background: #fffbf5;
  
  
  }
  
  .HomeSection12_area1_cont1_div1{
  
    display: flex;
    flex-direction: column;
  }
  
  .HomeSection12_area1_cont2_div1{
    display: flex ;
  justify-content: space-around;
  }
  .HomeSection12_area1_cont1_div1 >h1{
    
    
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 20px 0px;
  }
  /* .HomeSection3_area_video {
    position: absolute;
  } */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  
  @media (max-width: 1300px) {
    .HomeSection1_area_1_cont1_txt2 {
      font-size: 58px;
    }
    .HomeSection1_area_1_cont1_txt3 {
      font-size: 24px;
      margin-bottom: 50px;
    }
    .HomeSection1_area_1_cont1_txt2 {
      margin-bottom: 25px;
    }
  }
  @media (max-width: 1100px) {
    .HomeSection1_area_2 {
      width: 55%;
      margin-left: 1em;
    }
  }
  
  @media (max-width: 999px) {
    .unlock_potential_area {
      flex-direction: column;
    }
    .unlock_potential_area1 {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 0em;
      margin-bottom: 2em;
    }
    .unlock_potential_area_title {
      font-size: 42px;
      line-height: 1.2;
      font-weight: 600;
    }
    .HomeSection5_area_body {
      padding: 0em 0em;
    }
    .HomeSection1 {
      padding-top: 3em;
    }
    .HomeSection1_area_1_cont1_txt {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .HomeSection1_area_1_cont1_txt2 {
      margin-bottom: 25px;
      font-size: 48px;
    }
    .HomeSection1_area_1_cont1_txt3 {
      font-size: 24px;
      margin-bottom: 50px;
    }
    .HomeSection1_area_1_cont1_cards {
      align-items: center;
      width: 100%;
      justify-content: center;
    }
    .HomeSection1_area_2 {
      width: 90%;
      margin-left: 0;
      margin-top: 2em;
    }
    .HomeSection3_area_title {
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 1em;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 3;
      line-height: 1.2;
    }
  
    .HomeSection3_area_title_para {
      font-size: 18px;
      font-weight: 400;
      margin-top: 1em;
    }
  
    .HomeSection4 {
      padding: 6em 0em;
      padding-bottom: 0em;
    }
    .HomeSection4_area_title_div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 3em;
    }
    .HomeSection4_area_title {
      font-size: 48px;
      font-weight: 700;
      position: relative;
      line-height: 1;
    }
    .HomeSection4_area_para {
      width: 100%;
    }
    .HomeSection4_area_body {
      display: flex;
      align-items: flex-start;
      position: relative;
      flex-direction: column-reverse;
    }
    .HomeSection4_area_body_cont2 {
      width: 100%;
      margin-left: 0;
      position: relative;
    }
    .HomeSection4_area_body_cont1 {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;
      height: 100%;
    }
    .HomeSection4_area_body_cont1_div1 {
      display: flex;
      flex-direction: column;
      margin-bottom: 60px;
      text-align: center;
    }
    .HomeSection5 {
      padding-top: 100px;
      padding-bottom: 65px;
      position: relative;
    }
    .HomeSection5_area_title_div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 5em;
    }
    .HomeSection5_area_title {
      font-size: 48px;
      font-weight: 700;
      position: relative;
      line-height: 1;
    }
    .HomeSection5_area_para {
      margin-top: 1em;
      width: 100%;
    }
    .HomeSection8 {
      position: relative;
      padding: 7em 0em;
      padding-bottom: 7em;
    }
    .HomeSection8_area {
      display: flex;
      flex-direction: column;
    }
    .HomeSection8_area_1 {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      top: 0;
    }
    .HomeSection8_area_1_div2 {
      font-size: 48px;
      line-height: 1.2;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .HomeSection8_area_1_div3 {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 40px;
      width: 100%;
    }
    .HomeSection8_area_1_cont {
      margin-right: 0em;
      margin-bottom: 3em;
    }
    .HomeSection8_area_2 {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    .tarding_skills_top_wrapper {
      position: relative;
      width: 60%;
      height: 100%;
    }
    .tarding_skills_top_wrapper .six_month {
      text-align: center;
    }
    .HomeSection6 {
      position: relative;
      padding: 4em 0em;
      padding-bottom: 2em;
    }
    .HomeSection4_area_title_div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 3em;
    }
    .HomeSection4_area_title {
      font-size: 48px;
      font-weight: 700;
      position: relative;
      line-height: 1;
    }
    .HomeSection4_area_para {
      font-size: 18px;
      font-weight: 400;
      margin-top: 1em;
      width: 100%;
    }
    .HomeSection7 {
      position: relative;
      padding: 7em 0em;
      padding-bottom: 2em;
    }
    .HomeSection9 {
      padding: 4em 0em;
      position: relative;
      padding-top: 5em;
    }
  
    .HomeSection9_area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    }
    .HomeSection9_area_1 {
      position: relative;
      width: 85%;
      margin-left: 0em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0em;
    }
    .HomeSection9_area_2 {
      display: flex;
      flex-direction: column;
      margin-left: 0em;
      width: 100%;
      margin-top: 1em;
      text-align: center;
      align-items: center;
    }
    .HomeSection8_area_2_title {
      opacity: 1;
      font-size: 38px;
      line-height: 1.1;
      font-weight: 600;
      margin-bottom: 25px;
    }
    .HomeSection10 {
      position: relative;
      padding: 4em 0em;
    }
    .HomeSection10_area_1 {
      text-align: center;
      font-size: 48px;
      line-height: 1.3;
      font-weight: 600;
      margin-bottom: 30px;
      width: 100%;
    }
    .HomeSection11 {
      position: relative;
      padding: 4em 0em;
    }
    .HomeSection11_area {
      display: flex;
      align-items: center;
      padding: 6em 4em;
      flex-direction: column;
    }
    .HomeSection1_area {
      flex-direction: column;
    }
    .HomeSection1_area_1 {
      flex-direction: column;
      align-items: center;
    }
  
    .HomeSection3 {
      /* padding: 8em 0em; */
      padding-top: 100px;
      padding-bottom: 20px;
      position: relative;
    }
  
    .HomeSection7_area_body_details_div2_img {
      display: none;
    }
  
    .HomeSection7_area_body_details_div2::after {
      display: none;
    }
    .HomeSection7_area_body_details::after {
      display: none;
    }
    .HomeSection7_area_body_details_div2 {
      display: none;
    }
    .HomeSection4_area_title_div {
      margin-bottom: 2em;
    }
    .HomeSection7_area_body_details_div1 {
      margin-right: 0em;
    }
    .HomeSection7_area_body_details {
      margin-bottom: 30px;
    }
    .HomeSection11_area_1 {
      width: 100%;
      margin-right: 0em;
      margin-bottom: 3em;
    }
    .HomeSection11_area_1_para {
      width: 100%;
    }
    .HomeSection5_area_body_btn_div {
      margin-top: 1em;
    }
  
    .HomeSection9_area_1::after {
      display: none;
    }
  
    .HomeSection7_area_body {
      display: flex;
      flex-direction: column-reverse;
    }
    .HomeSection7_area_body_navigation::after {
      content: "";
      width: 100%;
      height: 3px;
      background: #5dc2ab;
      top: auto;
      bottom: -40px;
      position: absolute;
      border-radius: 50px;
    }
  
    .HomeSection7_area_body_navigation_cont1_active::before {
      content: "";
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #ffcf88;
      border-bottom: none;
      display: inline-block;
      position: absolute;
      top: auto;
      bottom: -48px;
      left: 50%;
      transform: translateX(-50%);
    }
    .HomeSection7_area_body_navigation_cont1_active::after {
      content: "";
      position: absolute;
      background: #ffcf88;
      width: 100%;
      top: auto;
      height: 3px;
      bottom: -40px;
      left: 0px;
      z-index: 9;
      border-radius: 50px;
      opacity: 1;
    }
    .HomeSection7_area_body_details_div1_unique_txt {
      margin-top: 20px;
      margin-bottom: 30px;
    }
  
    .HomeSection7_area_body_navigation {
      align-items: center;
      justify-content: space-between;
      display: flex;
      position: relative;
      margin-top: 0px;
      margin-bottom: 85px;
    }
    .HomeSection7_area_body_navigation_cont1_txt {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      text-align: center;
    }
  }
  @media (max-width: 767px) {
    .HomeSection7_area_body_navigation_cont1_txt_title {
      font-weight: 700;
      width: 60px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  
    div#\#mentor {
      padding-top: 10px !important;
    }
    .HomeSection7_area_body_navigation_cont1_img.w-36 {
      width: 5rem !important;
    }
    .HomeSection7_area_body {
      display: flex;
      flex-direction: column-reverse;
      overflow: scroll;
    }
    .HomeSection7_area_body_navigation {
      margin-bottom: 55px;
    }
    .HomeSection7_area_body_navigation_cont1_img.h-36 {
      height: 5rem !important;
    }
    .HomeSection2_area_img {
      width: 100% !important;
      margin-bottom: 2em !important;
    }
    .HomeSection2::after {
      bottom: 31px;
    }
    .HomeSection1_area_2 {
      width: 100%;
      margin-left: 0em;
    }
    .unlock_potential_area {
      flex-direction: column;
    }
    .HomeSection3_area_title {
      margin-bottom: -1em;
    }
    .HomeSection11_area_1_title {
      font-size: 28px !important;
    }
    .unlock_potential_area1 {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 0em;
      margin-bottom: 2em;
    }
    .unlock_potential_area_2 {
      width: 100%;
    }
    .unlock_potential_area_title {
      font-size: 32px;
    }
    .unlock_potential_area_para {
      color: #b7f0e3c4;
      margin-top: 1em;
      font-size: 16px;
    }
    .HomeSection1_area_1_cont1_txt2 {
      margin-bottom: 20px;
      margin-top: 20px;
      font-size: 38px;
      line-height: 1;
    }
    .HomeSection3_area_title {
      font-size: 38px;
      line-height: 1.1;
    }
    .HomeSection3_area_video_div_img {
      width: 60%;
    }
    .HomeSection4_area_title {
      font-size: 38px;
    }
    .HomeSection4_area_body_cont2 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .HomeSection4_area_body_cont2_img {
      width: 100%;
    }
    .HomeSection4_area_body_cont1_div1_title {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: 600;
    }
    .HomeSection4_area_body_cont1_div1 {
      margin-bottom: 50px;
    }
    .HomeSection5_area_title {
      font-size: 38px;
    }
    .HomeSection8 {
      padding: 4em 0em;
      padding-bottom: 4em;
    }
    .tarding_skills_top_wrapper {
      width: 100%;
    }
    .HomeSection7 {
      position: relative;
      padding: 4em 0em;
      padding-bottom: 2em;
    }
    .HomeSection7_area_title {
      font-size: 38px;
      font-weight: 700;
    }
    .HomeSection8_area_1_div2 {
      font-size: 38px;
    }
    .HomeSection4_area_para {
      font-size: 16px;
    }
    .HomeSection10_area_1 {
      font-size: 38px;
      line-height: 1.1;
    }
    .HomeSection11_area {
      padding: 4em 2em;
    }
    .HomeSection11_area_1_title {
      font-size: 38px;
    }
    .HomeSection11_area_1_para {
      font-size: 18px;
    }
    .HomeSection11_area_1 {
      margin-bottom: 1em;
    }
    .HomeSection7_area_body_details_div1_title_div2 {
      font-size: 32px;
    }
    .HomeSection10_area_2_cont1 {
      display: none;
    }
    .HomeSection10_area_2_cont2_div1 {
      margin-bottom: 2em;
    }
    .HomeSection5_area_title_div {
      margin-bottom: 2em;
    }
    .HomeSection5_area_body_cont1 {
      border-radius: 11px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 10px;
      border: solid 1px #539c8b;
      margin-bottom: 2em;
    }
    .HomeSection5_area_body_cont1_div1 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .HomeSection5_area_body_cont1_div1_para {
      text-align: right;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .HomeSection5_area_body_cont1_div_icon_div {
      display: none;
    }
    .HomeSection5_area_body_cont1_div3 {
      margin-bottom: 0px;
      margin-top: 0px;
    }
    .HomeSection5_area_body_cont1_div1_title {
      text-align: center;
    }
    .HomeSection1_area_1_cont1_txt3 {
      font-size: 18px;
      margin-bottom: 40px;
    }
    .HomeSection1_area_1_cont1_txt1 {
      font-size: 18px;
      font-weight: 700;
    }
    .HomeSection1_area_2_form_div_title {
      font-size: 24px;
    }
    .HomeSection10_area_2_cont2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 500px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-right: 15px;
      padding-bottom: 80px;
    }
    .HomeSection3_area_video_div_img {
      width: 100%;
    }
    .HomeSection1_area_1_cont1_cards {
      flex-direction: column;
    }
    .HomeSection1_area_1_cont1_cards_card1 {
      margin-right: 0px;
      max-width: 300px;
    }
    .HomeSection9 {
      padding: 2em 0em;
    }
  }
  /* ===================== */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  /* ===================== */
  