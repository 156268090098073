.react-tel-input .form-control {
    width: 100% !important;
    height: 100% !important;
    padding: 15px !important;
    border-radius: 7px !important;
    background: #fff !important;
    border: solid 1px #fff !important;
    color: #97a89f !important;
    font-weight: 500 !important;
    padding-left: 3.5em !important;
  }
  